<template>
    <div class="flex flex-wrap align-items-center justify-content-center p-fluid">
        <div class="col-12 md:col-5">
            <Toast />

            <!-- 顯示錯誤 -->
            <Dialog position="bottom" header="請修正輸入資料" v-model:visible="displayInvalidPopup" closable="false"
                dismissableMask="true" :breakpoints="{ '960px': '75vw' }" :style="{ width: '30vw' }" :modal="true">
                <p class="line-height-3 m-0">
                <ul id="example-1" class="p-1 m-0">
                    <div v-for="invalid in invalidSummary" :key="invalid" class="mt-2">
                        - {{ invalid }}
                    </div>
                </ul>
                </p>
                <template #footer>
                </template>
            </Dialog>
            <!-- 主頁面 -->
            <div class="card">
                <div class="mb-4 mt-4 w-full flex justify-content-center">
                    <span class="text-3xl font-bold">重設密碼</span>
                </div>
                <div class="grid formgrid">
                    <div class="col-12 mb-2 mt-4 lg:col-12 lg:mb-0">
                        <span class="p-input-icon-left p-float-label">
                            <i class="pi pi-phone" />
                            <InputText type="text" id="phone" v-model="phoneValue"
                                v-bind:class="{ 'p-invalid': isInvalidPhone }" />
                            <label for="phone">手機號碼</label>
                        </span>
                    </div>
                    <div class="col-12 mb-2 mt-4 lg:col-12 lg:mb-0">
                        <span class="p-input-icon-left p-float-label">
                            <i class="pi pi-lock" />
                            <InputText type="password" id="password" v-model="passwordValue"
                                v-bind:class="{ 'p-invalid': isInvalidPassword }" />
                            <label for="comment">欲設定之密碼 (至少包含2個英文字及4個數字)</label>
                        </span>
                    </div>
                    <div class="col-12 mb-2 mt-4 lg:col-12 lg:mb-0">
                        <span class="p-input-icon-left p-float-label">
                            <i class="pi pi-lock" />
                            <InputText type="password" id="password" v-model="passwordValue2"
                                v-bind:class="{ 'p-invalid': isInvalidPassword }" />
                            <label for="comment">再次確認密碼</label>
                        </span>
                    </div>
                    <div class="col-12 mb-2 mt-4 lg:col-12 lg:mb-0">
                        <div class="field-checkbox mb-0">
                            <Checkbox id="checkOption1" name="option" value="agree" v-model="checkboxValue" />
                            <label for="checkOption1">我同意使用條款、資料政策。</label>
                        </div>
                    </div>
                    <div class="col-12 mb-2 mt-4 lg:col-12 lg:mb-0">
                        <Button v-on:click="submitForm" label="開始簡訊認證" class="p-button-secondary p-button mr-2" />
                    </div>
                    <div class="col-12 mb-2 mt-4 lg:col-12 lg:mb-0">
                        * 如您未收到驗證簡訊，請確認是否有使用「拒收企業簡訊」功能之相關阻擋廣告訊息類之APP，謝謝
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    data() {
        return {
            /* values */
            phoneValue: "",
            passwordValue: "",
            passwordValue2: "",
            checkboxValue: "",

            // flags
            isInvalidName: false,
            isInvalidAddress: false,
            isInvalidPhone: false,
            isInvalidMail: false,
            isInvalidPassword: false,
            invalidSummary: "",

            // displays
            displayInvalidPopup: false,
        };
    },
    mounted() {
    },
    beforeUnmount() { },
    created() { },
    methods: {
        submitForm() {
            this.isInvalidName = false;
            this.isInvalidPhone = false;
            this.isInvalidMail = false;
            this.isInvalidPassword = false;

            // check name
            var wrongFmt = false;

            this.invalidSummary = [];


            var phone_check = new RegExp('^09[0-9]{8}$');
            if (this.phoneValue.trim().length <= 0 || phone_check.test(this.phoneValue.trim()) == false) {
                this.invalidSummary.push("手機號碼格式錯誤");
                this.isInvalidPhone = true;
                wrongFmt = true;
            }

            var password_check = /^(?=.*[0-9].*[0-9].*[0-9].*[0-9])(?=.*[a-zA-Z].*[a-zA-Z]).{6,}/;
            if (password_check.test(this.passwordValue) == false) {
                this.invalidSummary.push("密碼至少包含2個英文及4個數字");
                this.isInvalidPassword = true;
                wrongFmt = true;
            }

            if (this.passwordValue != this.passwordValue2) {
                this.invalidSummary.push("兩次輸入的密碼不一致");
                this.isInvalidPassword = true;
                wrongFmt = true;
            }


            if (this.checkboxValue != "agree") {
                this.invalidSummary.push("請同意使用條款，謝謝您");
                wrongFmt = true;
            }

            if (wrongFmt) {
                this.popupInvalidCheck();
                return;
            }
            this.sendSMS();
        },
        popupInvalidCheck() {
            this.displayInvalidPopup = true;
        },
        sendSMS() {
            axios.defaults.withCredentials = true;
            axios(process.env.VUE_APP_API_BASE_URL + "/sendSMS", {
                method: "GET",
                withCredentials: true,
                params: {
                    b: localStorage.brand,
                    s: "",
                    phone: this.phoneValue,
                    password: this.passwordValue
                },
            })
                .then((response) => {
                    console.log(response.data);
                    var status = response.data;
                    if (status == "success") {
                        this.$toast.add({ severity: 'success', summary: '簡訊已寄送', detail: '請留意簡訊收件匣', life: 3000 });
                        // Redirect back to login
                        setTimeout(() => {
                            this.$router.push({
                                path: "/", // Entrance
                                name: "login",
                            });
                        }, "3000")
                    } else
                        this.$toast.add({ severity: 'info', summary: '簡訊', detail: status, life: 3000 });
                })
                .catch((err) => {
                    console.log(err.response);
                });
        }
    },
    computed: {
    },
};
</script>

<style scoped lang="scss">
.p-text-purpply {
    color: var(--gray-700);
}

.btn {
    border-radius: 10px;
    border-bottom: 0px;
    display: inline-block;
    padding: 10px;
    position: relative;
    text-align: center;
    transition: background 600ms ease, color 600ms ease;
}

input[type="radio"].toggle {
    display: none;

    &+label {
        cursor: pointer;
        min-width: 120px;
        background-color: var(--purpply-unselected-color);

        &:hover {
            background: none;
            color: var(--purpply-primary-color);
        }

        &:after {
            background-color: var(--purpply-primary-color);
            content: "";
            height: 100%;
            position: absolute;
            top: 0;
            transition: left 200ms cubic-bezier(0.77, 0, 0.175, 1);
            width: 100%;
            z-index: -1;
        }
    }

    &.toggle-left+label {
        border-right: 0;

        &:after {
            left: 100%;
        }
    }

    &.toggle-right+label {
        margin-left: 0px;

        &:after {
            left: -100%;
        }
    }

    &:checked+label {
        cursor: default;
        color: #fff;
        background-color: var(--purpply-primary-color);
        transition: color 200ms;

        &:after {
            left: 0;
        }
    }
}
</style>
